import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialize } from 'src/redux/slices/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { verifyToken } from 'src/action/authService';

// ----------------------------------------------------------------------

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  useEffect(() => {
    dispatch(getInitialize());
  }, [dispatch]);

  const backToDashboard = useCallback(async () => {
    const isTokenValid = await verifyToken();
    if (location.pathname.includes('/dashboard')) {
      if (!user || !isTokenValid) {
        return <Navigate to="/auth/login" />;
      }
      return null;
    }

    return null;
  }, [user, location.pathname]);

  useEffect(() => {
    backToDashboard();
  }, [backToDashboard]);

  return children;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
